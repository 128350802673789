<template>
  <div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="详情图集" prop="lineImage">
            <a-upload
              name="file"
              :multiple="true"
              :file-list="form.lineImage"
              :action="IMG_API + '/oss/files'"
              @change="handleChange"
              :before-upload="beforeUpload"
            >
              <a-button v-if="this.form.lineImage.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
            <div class="upload_tip">单图</div>
            <div class="upload_tip">格式：png，jpg，jpeg格式</div>
            <div class="upload_tip">大小：不超过1M</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="班车通行方向" prop="singleordoubleline">
            <a-select
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="form.singleordoubleline"
              placeholder="请选择"
            >
              <a-select-option v-for="item in directionList" :key="item.id" :value="item.id">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-for="(item, index) in form.siteInfo" :key="item.indexNum">
        <a-col :span="1">
          <a-form-model-item prop="">
            <a-button
              @click="moveUp(index)"
              v-show="index != 0 && index != form.siteInfo.length - 1 && index != 1"
              size="small"
              type="link"
            >
              上移
            </a-button>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item
            :label="index === 0 ? '始发站' : index === form.siteInfo.length - 1 ? '终点站' : '站点名称'"
            :prop="'siteInfo.' + index + '.sitename'"
            :rules="rules.sitename"
          >
            <a-input placeholder="请输入" @change="$forceUpdate()" :max-length="60" v-model.trim="item.sitename" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item
            label="经纬度"
            :prop="'siteInfo.' + index + '.latitudeAndLongitude'"
            :rules="rules.latitudeAndLongitude"
          >
            <a-input
              placeholder="经度，纬度"
              :max-length="60"
              style="width: 100%"
              @change="$forceUpdate()"
              v-model.trim="item.latitudeAndLongitude"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="标签" prop="des">
            <a-input
              placeholder="请输入"
              @change="$forceUpdate()"
              :max-length="30"
              style="width: 80%"
              v-model.trim="item.des"
            />
            <colorPicker disabled style="margin-left: 10px" v-model="color1" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="标签" prop="desTwo">
            <a-input
              placeholder="请输入"
              @change="$forceUpdate()"
              :max-length="30"
              style="width: 80%"
              v-model.trim="item.desTwo"
            />
            <colorPicker disabled style="margin-left: 10px" v-model="color2" />
          </a-form-model-item>
        </a-col>
        <a-col :span="2">
          <a-form-model-item>
            <a-button
              @click="delede(index)"
              v-show="index != 0 && index != form.siteInfo.length - 1"
              size="small"
              type="danger"
            >
              删除
            </a-button>
          </a-form-model-item>
        </a-col>
        <a-col v-show="index === form.siteInfo.length - 2" :span="24">
          <a-form-model-item>
            <a-button @click="addStation(index)" style="margin-left: 19%" type="primary"> 新建站点 </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import { IMG_API } from "@/config/index";
export default {
  props: ["edmitform"],
  data() {
    let checkLatitudeAndLongitude = (rule, value, callback) => {
      // 判断字符是否包含英文或中文
      if (/.*[\u4e00-\u9fa5]+.*$/.test(value) || /.*[A-Za-z]+.*$/.test(value)|| value.indexOf(" ") >= 0) {
      } else {
        return callback();
      }
      // 不合法
      callback(new Error("经纬度格式不正确"));
    };
    let checkValue = (rule, value, callback) => {
      // 校验是否包含特殊字符
     let regex = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im; 
      if (!regex.test(value)) {
        return callback();
      }
      // 不合法
      callback(new Error("格式不正确请重新输入"));
    };
    return {
      IMG_API: IMG_API,
      rules: {
        lineImage: [{ required: true, message: "请上传文件", trigger: "change" }],
        singleordoubleline: [{ required: true, message: "请选择班车同行方向", trigger: "change" }],
        sitename: [
          { required: true, message: "请输入站点名称", trigger: "blur" },
          { validator: checkValue, trigger: "blur" },
        ],
        latitudeAndLongitude: [
          { required: true, message: "请输入经纬度", trigger: "blur" },
          { validator: checkLatitudeAndLongitude, trigger: "blur" },
        ],
      },
      directionList: [
        { id: 1, label: "单向 （即始发站到终点站）" },
        { id: 2, label: "双向" },
      ],
      color1: "#70B603",
      color2: "#F59A23",
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      indexNum: 4,
      form: {
        singleordoubleline: 1,
        lineImage: [],
        siteInfo: [
          {
            sitename: "",
            des: "",
            latitudeAndLongitude: "",
            desTwo: "",
            sort: "",
            indexNum: "1",
          },
          {
            sitename: "",
            des: "",
            latitudeAndLongitude: "",
            desTwo: "",
            sort: "",
            indexNum: "2",
          },
          {
            sitename: "",
            des: "",
            latitudeAndLongitude: "",
            desTwo: "",
            sort: "",
            indexNum: "3",
          },
          {
            sitename: "",
            des: "",
            latitudeAndLongitude: "",
            desTwo: "",
            sort: "",
            indexNum: "4",
          },
        ],
      },
    };
  },
  methods: {
    //上移
    moveUp(index) {
      // 需要上移的数据
      let upData = JSON.parse(JSON.stringify(this.form.siteInfo[index]));
      // 需要下移的数据
      let downData = JSON.parse(JSON.stringify(this.form.siteInfo[index - 1]));
      this.form.siteInfo[index - 1] = upData;
      this.form.siteInfo[index] = downData;
      this.$refs.ruleForm.clearValidate();
      //刷新页面
      this.$forceUpdate();
    },
    //删除数组某一项
    delede(index) {
      this.form.siteInfo.splice(index, 1);
      this.$refs.ruleForm.clearValidate();
      this.$forceUpdate();
    },
    //新建站点
    addStation(index) {
      let obj = {
        sitename: "",
        des: "",
        latitudeAndLongitude: "",
        desTwo: "",
        sort: "",
        indexNum: this.indexNum + 1,
      };
      this.indexNum = this.indexNum + 1;
      this.form.siteInfo.splice(index + 1, 0, obj);
    },
    handleChange({ fileList }) {
      if (fileList.length != 0) {
        const isJPG =
          fileList[0].type === "image/jpeg" || fileList[0].type === "image/png" || fileList[0].type === "image/jpg";
        const isLt2M = fileList[0].size / 1024 / 1024 < 1;
        if (isJPG && isLt2M) {
          this.form.lineImage = fileList;
        }
      } else {
        this.form.lineImage = [];
      }
    },
    //上传文件之前校验图片大小
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png、jpeg格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      }

      return isJPG && isLt2M;
    },
    //班车时刻表校验规则
    parentHandleclick() {
      this.$refs.ruleForm.validate((valid) => {
        let obj = {};
        if (valid) {
          obj = {
            type: true,
            form: this.form,
          };
          this.$emit("BueVerificationRules", obj);
        } else {
          obj = {
            type: false,
          };
          this.$emit("BueVerificationRules", obj);
        }
      });
    },
    // 清空规则
    clearRules() {
      this.$refs.ruleForm.clearValidate();
    },
  },
  mounted() {},
  watch: {
    edmitform(val, newval) {
      if (val.id) {
        this.form = val;
      }
    },
  },
};
</script>
<style scoped>
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
</style>
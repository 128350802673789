<template>
  <div>
    <a-form-model ref="ruleForm" :model="form">
      <h2>{{ title }}</h2>
      <h3 style="margin-top: 15px">基础信息</h3>
      <basicInformation
        ref="basicInformation"
        :edmitform="edmitform"
        @verificationRules="verificationRules"
      ></basicInformation>
      <h3>班车时刻表</h3>
      <busTimetable
        ref="busTimetable"
        :edmitform="edmitform"
        @BueVerificationRules="BueVerificationRules"
      ></busTimetable>
      <h3>线路轨迹信息</h3>
      <lineTrack ref="lineTrack" :edmitform="edmitform" @checklineTrackRules="checklineTrackRules"></lineTrack>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 10 }">
        <a-button type="primary" :loading="onSubmitLoading" @click="handleOk"> 提交 </a-button>
        <a-button style="margin-left: 10px" @click="handleCancel"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import basicInformation from "../components/basicInformation";
import busTimetable from "../components/busTimetable";
import lineTrack from "../components/lineTrack";
import * as api from "@/api/userMessage";
import moment from "moment";
export default {
  components: {
    basicInformation,
    busTimetable,
    lineTrack,
  },
  data() {
    return {
      onSubmitLoading: false,
      title: "",
      verificationRulesObj: false,
      BueVerificationRulesObj: false,
      lineTrackRulesObj: false,
      form: {},
      edmitform: {},
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.getDetail();
      this.title = "编辑通勤巴士";
    } else {
      this.title = "添加通勤巴士";
    }
  },
  created() {
    moment(moment()).unix();
  },
  methods: {
    async getDetail() {
      const res = await api.getBusById({ id: this.id });
      if (res.code === "200") {
        var obj = {
          uid: "1",
          name:res.data.imageName,
          status: "done",
          url: res.data.lineImage,
        };
        res.data.lineImage = [obj];
        if (res.data.giveProject) {
          res.data.giveProject = res.data.giveProject.split(",");
        } else {
          res.data.giveProject = [];
        }
        res.data.siteInfo.forEach((item) => {
          item.latitudeAndLongitude = item.sitelongitude + "," + item.sitelatitude;
        });
        res.data.singleordoubleline = Number(res.data.singleordoubleline);
        res.data.endbustime = moment(res.data.endbustime, "HH:mm");
        res.data.firstbustime = moment(res.data.firstbustime, "HH:mm");
        this.edmitform = res.data;
      }
    },
    //取消
    handleCancel() {
      this.$router.go(-1);
    },
    //基础信息接收校验参数
    verificationRules(val) {
      this.verificationRulesObj = val;
    },
    //班车时刻表接收校验参数
    BueVerificationRules(val) {
      this.BueVerificationRulesObj = val;
    },
    //线路轨迹信息校验
    checklineTrackRules(val) {
      this.lineTrackRulesObj = val;
    },
    //确定按钮
    async handleOk() {
      await this.$refs.busTimetable.parentHandleclick();
      await this.$refs.basicInformation.parentHandleclick();
      await this.$refs.lineTrack.parentHandleclick();
      // 根据id判断编辑还是新增
      if (this.id) {
        //判断基础信息和班车时刻表规则状态
        if (this.verificationRulesObj.type && this.BueVerificationRulesObj.type && this.lineTrackRulesObj.type) {
          this.onSubmitLoading = true;
          // 获取各个返回的数据
          let obj = {
            ...this.verificationRulesObj.form,
            ...this.BueVerificationRulesObj.form,
            ...this.lineTrackRulesObj.form,
          };
          // 数据解析  解析成后端接口需要的数据格式
          let dataValue = JSON.parse(JSON.stringify(obj));
          dataValue.endbustime = moment(dataValue.endbustime).format("HH:mm");
          dataValue.firstbustime = moment(dataValue.firstbustime).format("HH:mm");
          dataValue.giveProject = dataValue.giveProject.join(",");
          let imgList = JSON.parse(JSON.stringify(dataValue.lineImage[0]));
          // 判断编辑有没有重新上传图片
          if (imgList.response) {
            dataValue.lineImage = imgList.response.redirect_uri;
            dataValue.imageName = imgList.response.name;
          } else {
            dataValue.lineImage = imgList.url;
            dataValue.imageName = imgList.name;
          }
          dataValue.firstsite = dataValue.siteInfo[0].sitename;
          dataValue.endsite = dataValue.siteInfo[dataValue.siteInfo.length - 1].sitename;
          dataValue.id = this.id;
          dataValue.siteInfo.forEach((element, index) => {
            element.sort = index + 1;
          });
          const res = await api.updateCommuterBus(dataValue);
          this.onSubmitLoading = false;
          if (res.code === "200") {
            this.$message.success("编辑成功!");
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        }
      } else {
        //判断基础信息和班车时刻表规则状态
        if (this.verificationRulesObj.type && this.BueVerificationRulesObj.type && this.lineTrackRulesObj.type) {
          this.onSubmitLoading = true;
          let obj = {
            ...this.verificationRulesObj.form,
            ...this.BueVerificationRulesObj.form,
            ...this.lineTrackRulesObj.form,
          };
          let dataValue = JSON.parse(JSON.stringify(obj));
          dataValue.endbustime = moment(dataValue.endbustime).format("HH:mm");
          dataValue.firstbustime = moment(dataValue.firstbustime).format("HH:mm");
          dataValue.giveProject = dataValue.giveProject.join(",");
          let imgList = JSON.parse(JSON.stringify(dataValue.lineImage[0]));
          dataValue.lineImage = imgList.response.redirect_uri;
          dataValue.imageName = imgList.response.name;
          dataValue.firstsite = dataValue.siteInfo[0].sitename;
          dataValue.endsite = dataValue.siteInfo[dataValue.siteInfo.length - 1].sitename;
          dataValue.siteInfo.forEach((element, index) => {
            element.sort = index + 1;
          });
          const res = await api.insertCommuterBus(dataValue);
          this.onSubmitLoading = false;
          if (res.code === "200") {
            this.$message.success("添加成功!");
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        }
      }
    },
  },
};
</script>
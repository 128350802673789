<template>
  <div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="线路名称" prop="linename">
            <a-input placeholder="请输入" v-model.trim="form.linename" :max-length="40" />
            <div class="upload_tip">支持中英文数字，最多可输入40个字符</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="运营时间" prop="operationtime">
            <a-input placeholder="请输入" :max-length="20" v-model.trim="form.operationtime" />
            <div class="upload_tip">支持中英文数字输入</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="首班车时间" prop="firstbustime">
            <a-time-picker style="width: 100%" v-model="form.firstbustime" format="HH:mm" />
            <div class="upload_tip">24小时制，选择时分即可</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="末班车时间" prop="endbustime">
            <a-time-picker style="width: 100%" v-model="form.endbustime" format="HH:mm" />
            <div class="upload_tip">24小时制，选择时分即可，末班车需大于首班车时间</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="透出项目" prop="giveProject">
            <a-select
              collapse-tags
              :maxTagCount="2"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
              :maxTagTextLength="5"
              v-model="form.giveProject"
              mode="multiple"
              placeholder="请选择"
            >
              <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
                {{ item.project_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="状态" prop="busState">
            <a-select
              v-model="form.busState"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
              placeholder="请选择"
            >
              <a-select-option v-for="item in stateList" :key="item.id" :value="item.id">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import * as api from "@/api/userMessage";
export default {
  props: ["edmitform"],
  data() {
    //校验时间
    let checkTime = (rule, value, callback) => {
      if (this.form.endbustime > this.form.firstbustime) {
        return callback();
      }
      // 不合法
      callback(new Error("末班车时间需大于首班车时间"));
    };
    let checkValue = (rule, value, callback) => {
       // 校验是否包含特殊字符
      //  let regex = new RegExp("^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_])");
      let regex = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im; 
      if (!regex.test(value)) {
        return callback();
      }
      // 不合法
      callback(new Error("格式不正确请重新输入"));
    };
    return {
      rules: {
        linename: [
          { required: true, message: "请输入线路名称", trigger: "blur" },
          { validator: checkValue, trigger: "blur" },
        ],
        operationtime: [
          { required: true, message: "请输入运营时间", trigger: "blur" },
          { validator: checkValue, trigger: "blur" },
        ],
        firstbustime: [{ required: true, message: "请选择首班车时间", trigger: "change" }],
        busState: [{ required: true, message: "请选择状态", trigger: "change" }],
        endbustime: [
          { required: true, message: "请选择末班车时间", trigger: "change" },
          { validator: checkTime, trigger: "change" },
        ],
      },
      projectList: [],
      stateList: [
        { id: 0, label: "启用" },
        { id: 1, label: "禁用" },
      ],
      form: {
        linename: "",
        operationtime: "",
        firstbustime: "",
        endbustime: "",
        busState: undefined,
        giveProject: [],
      },
    };
  },
  methods: {
    //基础信息校验规则
    parentHandleclick() {
      this.$refs.ruleForm.validate((valid) => {
        let obj = {};
        if (valid) {
          obj = {
            type: true,
            form: this.form,
          };
          this.$emit("verificationRules", obj);
        } else {
          obj = {
            type: false,
          };
          this.$emit("verificationRules", obj);
        }
      });
    },
    // 清空规则
    clearRules() {
      this.$refs.ruleForm.clearValidate();
    },
    async getProject() {
      const res = await api.getProjectByUserId();
      if (res.code == "200") {
        let list= res.data.filter((item)=>{
             return item!=null
        })
        this.projectList = list;
      }
    },
  },
  mounted() {
    this.getProject();
  },
  watch: {
    edmitform(val, newval) {
      if (val.id) {
        this.form = val;
      }
    },
  },
};
</script>
<style scoped>
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
::v-deep .lableWidth .ant-col-7 {
  width: 14.5%;
}
::v-deep .lableWidth .ant-col-15 {
  width: 81%;
}
</style>
<template>
  <div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
      <a-row>
        <a-col :span="24">
          <a-form-model-item class="lableWidth" label="终端设备/轨迹" prop="terminalAndTrajectory">
            <a-textarea placeholder="请输入" v-model.trim="form.terminalAndTrajectory" />
            <div class="upload_tip">
              请按照终端设备ID（轨迹ID），终端设备ID（轨迹ID）的格式进行填写，如1234（abc），2345（edc）
            </div>
            <div class="upload_tip">若没有轨迹ID括号内容可置空，但务必加上括号</div>
            <div class="upload_tip">可输入中英文，数字，符号，不可输入空格</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item class="lableWidth" label="班车线路图" prop="lineinfojson">
            <a-textarea placeholder="请输入" v-model.trim="form.lineinfojson" />
            <div class="upload_tip">复制高德地图中下载下来的线路图，非必要请勿修改</div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
export default {
  props: ["edmitform"],
  data() {
    let checkValue = (rule, value, callback) => {
      // 判断字符是否是中文
      let type = /.*[\u4e00-\u9fa5]+.*$/.test(value) || value.indexOf(" ") >= 0;
      if (type) {
      } else {
        return callback();
      }
      // 不合法
      callback(new Error("终端设备/轨迹输入格式不正确"));
    };
    return {
      form: {
        terminalAndTrajectory: "",
        lineinfojson: "",
      },
      rules: {
        terminalAndTrajectory: [{ validator: checkValue, trigger: "blur" }],
        lineinfojson: [{ required: true, message: "请输入班车线路图", trigger: "blur" }],
      },
    };
  },
  methods: {
    //线路轨迹信息校验规则
    parentHandleclick() {
      this.$refs.ruleForm.validate((valid) => {
        let obj = {};
        if (valid) {
          obj = {
            type: true,
            form: this.form,
          };
          this.$emit("checklineTrackRules", obj);
        } else {
          obj = {
            type: false,
          };
          this.$emit("checklineTrackRules", obj);
        }
      });
    },
    // 清空规则
    clearRules() {
      this.$refs.ruleForm.clearValidate();
    },
  },
  mounted() {},
  watch: {
    edmitform(val, newval) {
      if (val.id) {
        this.form = val;
      }
    },
  },
};
</script>
<style scoped>
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
::v-deep .lableWidth .ant-col-3 {
  width: 11.5%;
}
::v-deep .lableWidth .ant-col-15 {
  width: 81%;
}
</style>